import { render, staticRenderFns } from "./BindingPage.vue?vue&type=template&id=939b4906&scoped=true&"
import script from "./BindingPage.vue?vue&type=script&lang=js&"
export * from "./BindingPage.vue?vue&type=script&lang=js&"
import style0 from "./BindingPage.vue?vue&type=style&index=0&id=939b4906&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939b4906",
  null
  
)

export default component.exports